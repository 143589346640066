import React from 'react';
import Header from '../../components/Header';
import AppFooter from '../../components/AppFooter';
import style from './style.css';

const QuestLayout = props => {
    return (
        <div className={style.wrapper}>
            <Header className={props.className} />
            <div className={style.body}>
                <div className={props.className}>{props.children}</div>
            </div>
            <AppFooter />
        </div>
    );
};
export default QuestLayout;
