import React from 'react';
import { SessionPlayer } from 'cccisd-laravel-assessment';
import style from '../DeploymentCenter/style.css';

const SessionPlayerPage = props => {
    const isWithinWorkingHours = () => {
        const now = new Date();
        const day = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
        const hour = now.getHours(); // 0-23 hours format
        // Check if the current day is Monday-Friday and time is between 6am-6pm
        const isWeekday = day >= 1 && day <= 5; // Monday-Friday
        const isWorkingHour = hour >= 6 && hour < 18; // 6am-6pm
        return isWeekday && isWorkingHour;
    };

    if (!isWithinWorkingHours()) {
        return (
            <div className="container">
                <div className={style.panel}>
                    <div className={style.panelLeft}>
                        <div className={style.panelHead}>
                            <div className={style.pageTitle}>Sorry, this assessment is currently closed.</div>
                        </div>
                        <div className={style.panelBody}>
                            <div className={style.pageDescription}>
                                <span className={style.descriptionText}>
                                    Please return here any time from 6am to 6pm on Monday through Friday to complete the
                                    assessment.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={style.panelRight} />
                </div>
            </div>
        );
    }

    return <SessionPlayer {...props} />;
};

export default SessionPlayerPage;
